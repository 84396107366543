import request from '@/utils/request'

/**
 * 查询滑块验证图片
 */
export function slidingBlockFindOne() {
    return request({
        url: '/api/euser/slidingBlock/findOne',
        method: 'get',
        params: {
        }
    })
}

/**
 * 滑块验证图片
 */
export function slidingBlockCheck(data) {
    return request({
        url: '/api/euser/slidingBlock/check',
        method: 'get',
        params: data
    })
}
