<template>
  <div class="loginContent">
    <header><img src="../../assets/login/logo.png"></header>
    <div class="home">
      <div class="bg">
        <div class="login">
          <div class="title"><span>欢迎登录</span></div>
          <el-form ref="loginForm" :model="loginForm" class="login_form" :rules="rules">
            <el-form-item prop="username">
              <div class="label">
                账号
              </div>
              <el-input v-model="loginForm.username" maxlength="11" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item prop="password" class="pwd">
              <div class="label">
                <div class="password">密码</div>
<!--                <el-link type="primary" class="reset" :underline="false" href="/reset">忘记密码？</el-link>-->
              </div>
              <el-input :type="changeType()" v-model="loginForm.password" @keyup.enter.native="submitForm('loginForm')" placeholder="请输入您的密码" maxlength="16"></el-input>
              <i :class="[show?'iconfont iconicon-28':'iconfont iconicon-27']" style="color:#409EFF;" @click="show=!show" v-if="loginForm.password&&loginForm.password.length>0"></i>
            </el-form-item>
            <el-form-item>
              <div class="label"> <span>安全验证</span>
                <span class="textTips">拖动下方滑块完成拼图
                  <el-button type="text" class="tipsBtn" @click="getImageVerifyCode" :disabled="verifyStatus">刷新</el-button>
                </span>
              </div>
                <!-- :width="380"
                :height="120" -->
              <slideverify
                :l="0"  ref="dialogopen"
                :r="0"
                :block_y="puzzePass.block_y"
                :imgurl="puzzePass.imgurl"
                :miniimgurl="puzzePass.miniimgurl"
                @refresh="getImageVerifyCode"
                @success="onSuccess"
                @fail="onFail"
                :slider-text="puzzePass.text"
              ></slideverify>
                <!-- -->
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="button" @click="submitForm('loginForm')" :disabled="canLogin" :loading='loginLoading'>登录</el-button>
            </el-form-item>
            <div class="register">
              <!--              <span class="hasThm">没有账号？</span>
                          <el-link type="primary" href="/register">立即注册</el-link>-->
            </div>
          </el-form>
        </div>
        <div class="left">
          <img src="@/assets/login/banner.webp" alt="">
        </div>
      </div>
      <!-- <el-link href="/" class="logo" :underline="false"></el-link> -->
    </div>
    <!-- <div class="app-footer">
      <p class="mt60">地址：武汉市东湖开发区华中科技大学科技园天喻楼 | 邮政编码：430223 | 电话：027-87920300</p>
      <p class="mb60">传真：027-87920306 | E-Mail：webmaster@whty.com.cn | 鄂ICP备05002327 | 鄂公安网安备42018502000263号</p>
    </div> -->
  </div>
</template>

<script>
  import { mapGetters, } from 'vuex'
  import slideverify from '@/components/newCap'
  import { slidingBlockFindOne, slidingBlockCheck } from '@/api/login'
  import Cookies from "js-cookie";
  export default {
    components: {
      slideverify
    },
    data() {
      //   var confirmPwd = (rule, value, callback) => {
      //     if (value === '') {
      //       this.message = '请输入验证码'
      //     } else {
      //       callback()
      //     }
      //   }
      return {
        message: '',
        failEvents: undefined,
        canLogin: false,
        puzzePass: {
          visible: false,
          block_y: 0,
          // imgurl: img1,
          imgurl : '',
          // miniimgurl: img2,
          miniimgurl: '',
          text: '右滑',
          validId: ''
        }, // 滑块字段
        loginForm: {
          username: '',
          password: '',
        },
        codeSrc: '',
        rules: {
          username: [
            { required: true, message: '请输入账号', trigger: 'blur' },
            { pattern: '^1[3|4|5|7|8|9][0-9]{9}$', message: '账号格式不正确，请输入正确的账号', trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            // { pattern: '(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,16}', message: '密码必须包含数字、字母和符号，长度至少为8位且不超过16位', trigger: 'blur' }
          ],
        },
        checked: false,
        show: true,
        type: 'password',
        loginLoading: false,

        verifyStatus : false,  //滑动校验状态
      }
    },
    computed: {
      ...mapGetters([
        'login'
      ])
    },
    mounted() {
      this.getImageVerifyCode();
    },
    methods: {
      // 提交表单
      submitForm(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            if(this.verifyStatus){
              this.loginLoading = true
              this.$store.dispatch('LoginByUsername', this.loginForm).then((res) => {
                this.loginLoading = false
                Cookies.set('eaycUserId', res.data.user.id)
                Cookies.set('Authorization',res.data.token)
                if(res.success){
                  this.$router.push({
                    path: '/home',
                  })
                }else{
                  einvAlert.error("登录失败",res.message)
                  this.$refs.dialogopen.reset()
                  this.verifyStatus = false
                }
              }).catch(err => {
                this.loginLoading = false;
              })
            }else{
              this.$refs.dialogopen.reset()
              this.verifyStatus = false
              einvAlert.error("登录失败",'请先完成滑块验证')
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      // 是否显示密码
      changeType() {
        if (this.loginForm.password.length == 0) {
          this.show = true
        }
        if (this.show == true) {
          return 'password'
        } else {
          return 'text'
        }
      },

      getImageVerifyCode () {
        slidingBlockFindOne().then(res => {
          if(res.success){
            this.puzzePass.validId = res.data.validId
            this.puzzePass.block_y = 0
            this.puzzePass.imgurl = res.data.checkImgUrls[0]
            this.puzzePass.miniimgurl = res.data.checkImgUrls[1]
            // 如果图标是后台获取,base64图片赋值
            // this.puzzePass.imgurl =  'data:image/png;base64,' + imgobj.bigImage
            // this.puzzePass.miniimgurl = 'data:image/png;base64,' + imgobj.smallImage
            this.$refs.dialogopen && this.$refs.dialogopen.reset(this.puzzePass.block_y);
          }
          }).catch(error => {
            console.log(error);
        })
      },
      onFail () {
        console.log('fail')
      },
      // 验证码拉动距离返回
      onSuccess (left) {
        console.log('succss',this.puzzePass.validId, left)
        slidingBlockCheck({"validId":this.puzzePass.validId,"width":left}).then(res=>{
          if(res.success){
            this.$refs.dialogopen.handleSuccess()
            this.verifyStatus = true;
            if(this.failEvents !== undefined){
              this.clearVerifyReset()
            }
            this.verifyReset()
          }else{
            this.$refs.dialogopen.handleFail()
          }
        }).catch(error=>{
          this.$refs.dialogopen.handleFail()
        })
      },

      // 刷新验证码
      onRefresh() {
        this.getImageVerifyCode()
        this.$refs.dialogopen.containerFail = false
      },
      //5分钟滑块验证失效
      // 倒计时
      verifyReset() {
         this.failEvents = setTimeout(() => {
            this.$refs.dialogopen.reset()
        }, 1000*60*10);
      },
      clearVerifyReset(){
        clearTimeout(this.failEvents)
      }
    },
    watch: {
      'loginForm.username'(val){
        if(val){
          this.$refs.dialogopen.reset()
          this.verifyStatus = false
        }
      },
      'loginForm.password'(val){
        if(val){
          this.$refs.dialogopen.reset()
          this.verifyStatus = false
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .loginContent {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  header{
    opacity: 1;
    width: 100%;
    height: 48px;
    line-height: 42px;
    background: #ffffff;
    border-bottom: 0.15rem solid #F1F3FF;
    box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.20);
    font-size: 20px;
    font-family: Microsoft YaHei, Microsoft YaHei-Bold;
    font-weight: 700;
    text-align: left;
    color: #333333;
    text-indent: 30px;
    padding-top: 8px;
    img {
      height: 24px;
    }
  }
  .home {
    background: #fff;
    height: 85%;
    width: 100%;
    background-size: 100% 100%;
    position: relative;
    .bg {
      position: absolute;
      top: 75%;
      left: 45%;
      width: 960px;
      height: 57rem;
      transform: translate(-50%, -50%);
      .left {
        position: absolute;
        width: 31.25rem;
        height: 100%;
        top: 50px;
        background-position: center;
        left: 0;
        img {
          width: 31.25rem;
          height: 27.08rem;
        }
      }
      .login {
        position: absolute;
        left: 34rem;
        top: 0;
        bottom: 0;
        padding: 36px 46px;
        width: 24.6rem;
        height: 35.5rem;
        opacity: 1;
        background: #ffffff;
        border: 1px solid #dbdbdb;
        border-radius: 20px;
        box-shadow: 0px 6px 18px 2px rgba(55,99,169,0.10);
        .title {
          text-align: center;
          margin-bottom: 15px;
          font-size: 28px;
          font-family: Microsoft YaHei, Microsoft YaHei-Regular;
          font-weight: 400;
          color: #333333;
          span{
            border-bottom: 0.18rem solid #409EFF;
            padding-bottom: 7px;
          }
        }
        .reset{
          font-size: 16px;
          color: #409EFF;
        }
        .login_form {
          .pwd i {
            position: absolute;
            right: 14px;
            top: 74%;
            transform: translateY(-50%);
          }
          .el-form-item {
            margin-bottom: 22px;
            position: relative;
            .error {
              position: absolute;
              bottom: -40px;
              color: #e80000;
            }
            .el-button {
              width: 100%;
              height: 46px;
              font-size: 16px;
            }
            .iconxianshi {
              color: #409EFF !important;
            }
            .el-form-item__content {
              margin-left: 0 !important;
              .codeImg {
                height: 50px;
                margin-left: 5%;
                width: 95%;
                border: 1px solid #e0e0e0;
                border-radius: 9px;
                cursor: pointer;
              }
            }
            .el-input__inner {
              color: #222;
              height: 48px;
            }
            .label {
              display: flex;
              justify-content: space-between;
              font-size: 16px;
            }
            .textTips{
              cursor: pointer;
              font-size: 16px;
              .tipsBtn{
                width: auto;
                height: auto;
                font-size: 16px;
                color: #409EFF;
                font-style: normal;
                display: inline-block;
                text-decoration: underline;
              }
            }
          }
          .el-checkbox {
            margin-bottom: 24px;
            font-size: 14px;
            .el-checkbox__input,
            .el-checkbox__label {
              vertical-align: top;
            }
          }
          .el-icon-view:before {
            content: "";
          }
          .register {
            float: right;
            font-size: 18px;
            .hasThm {
              color: #999999;
            }
            .el-link {
              vertical-align: bottom;
              font-size: 18px;
              color: #409EFF;
            }
            /deep/.el-link.is-underline:hover:after {
              border-color: #409EFF;
            }
          }
        }
      }
    }
  }
  .app-footer {
    position: absolute;
    height: 12%;
    width: 100%;
    // padding: 20px;
    bottom: 0;
    overflow: hidden;
    background: #F1F3FF;
    p {
      padding: 0;
      margin: 0;
      color: #333333;
      font-size: 14px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      line-height: 35px;
      text-align: center;
    }
    .mt60 {
      margin-top: 24px;
    }
  }
</style>
